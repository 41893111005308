import React, { useState } from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import { Container, Col, Row } from "react-bootstrap";

import PaymentLogo1 from "../assets/images/payments/visa.png";
import PaymentLogo2 from "../assets/images/payments/mastercard.png";
import PaymentLogo3 from "../assets/images/payments/Skrill.png";
import PaymentLogo4 from "../assets/images/payments/paytm.png";
import PaymentLogo5 from "../assets/images/payments/googlepay.png";

import ProviderLogo1 from "../assets/images/providers/evolution.jpg";
import ProviderLogo2 from "../assets/images/providers/ezugi.jpg";
import ProviderLogo3 from "../assets/images/providers/spribe.png";
import ProviderLogo4 from "../assets/images/providers/netent.png";
import ProviderLogo5 from "../assets/images/providers/redtiger.png";
import ProviderLogo6 from "../assets/images/providers/Paragmatic.png";
import ProviderLogo7 from "../assets/images/providers/ProviderImg7.jpg";
import PaymentLogo6 from "../assets/images/payments/UPI.png";
import PaymentLogo7 from "../assets/images/payments/phonepe.png";

import HomeIcon from "../assets/images/icons/homeicon.png";
import MenuIcon from "../assets/images/icons/menuicon.png";
import CasinoIcon from "../assets/images/icons/casino-white.png";
import SportsIcon1 from "../assets/images/icons/Sports-white.png";
import LogInIcon from "../assets/images/icons/login-white.png";
import SupportIcon from "../assets/images/icons/Support-white.png";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import XPGIcon from "../assets/images/icons/poker-chip.png";

import Eighteenplus from "../assets/images/icons/footer/18plus.svg";
import Ripple from "../assets/images/icons/footer/ripple.svg";
import Dogecoin from "../assets/images/icons/footer/dogecoin.svg";
import Tron from "../assets/images/icons/footer/tron.svg";
import Bitcoincash from "../assets/images/icons/footer/bitcoin-cash.svg";
import Tether from "../assets/images/icons/footer/tether.svg";
import Etherem from "../assets/images/icons/footer/ethereum.svg";
import Bitcoin from "../assets/images/icons/footer/bitcoin.svg";
import Litcoin from "../assets/images/icons/footer/litecoin.svg";
import ResponsibleGaming from "../assets/images/icons/footer/safe-gamble.svg";

import Security from "../assets/images/icons/footer/security.png";
import Security1 from "../assets/images/icons/footer/security1.png";
import LoginModal from "../views/components/authModals/LoginModal";
import MobileNumberModal from "../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../views/components/authModals/RegisterModal";
import { APP_CONST } from "../config/const";

const BeforeLoginFooter = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);


  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
  });

  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };
  return (
    <footer className="footer">
      <Container fluid>
        <div className="footer-section">
          <div className="footer-right-part">
            <div className="providers_img">
              <a href="#">
                <img src={RNG} loading="lazy" alt="RNG Certified" />
              </a>
              <a href="#">
                <img src={Gambling} loading="lazy" alt="Gambling Commission" />
              </a>
              <a href="#">
                <img
                  src={ResponsibleG}
                  loading="lazy"
                  alt="Responsible Gaming"
                />
              </a>
              <a href="#">
                <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
              </a>
            </div>
          </div>
          <div className="footer-left-part">
            <div className="footer-menu-web">
              <div>
                <h3>SOLUTIONS</h3>
                <ul>
                  <li>
                    <a href="/disputeresolutiions">Dispute Resolution</a>
                  </li>

                  <li>
                    <a href="/payouts">Payout</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3>SUPPORT</h3>
                <ul>
                  <li>
                    <a href="/responsiblegames">Responsible Gaming</a>
                  </li>
                  <li>
                    <a href="/selfexclusion">Self Exclusion</a>
                  </li>

                  <li>
                    <a href="/registration">Registration</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-menu-web">
              <div>
                <h3>COMPANY</h3>
                <ul>
                  <li>
                    <a href="/contactuss">Contact US</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3>LEGAL</h3>
                <ul>
                  <li>
                    <a href="/privacypolicys">Privacy</a>
                  </li>
                  <li>
                    <a href="/termss">Terms</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-content d-none">
          <div className="logo-wrapper">
            <ul>
              <li>
                <img src={Litcoin} alt="logo" />
              </li>
              <li>
                <img src={Bitcoin} alt="logo" />
              </li>
              <li>
                <img src={Etherem} alt="logo" />
              </li>
              <li>
                <img src={Tron} alt="logo" />
              </li>
              <li>
                <img src={Ripple} alt="logo" />
              </li>
              <li>
                <img src={Dogecoin} alt="logo" />
              </li>
              <li>
                <img src={Bitcoincash} alt="logo" />
              </li>
              <li>
                <img src={Tether} alt="logo" />
              </li>
              <li>
                <img src={ResponsibleGaming} alt="logo" />
              </li>
              <li>
                <img src={Eighteenplus} alt="logo" />
              </li>
            </ul>
          </div>
        </div>

        <div className="BottomFooterPart">
          <img src={appDetails?.LOGO_URL} alt="Logo" />
          <p>
            Copyright © 2024 <span>Barbarjeeto </span> | All rights reserved.
          </p>

          <p className="paraContent">
            barbarjeeto is owned and operated by Medium Rare N.V., registration
            number: 145353, registered address: Korporaalweg 10, Willemstad,
            Curaçao. Contact us at support@barbarjeeto.com. Payment agent
            company is Medium Rare Limited with address 7-9 Riga Feraiou,
            LIZANTIA COURT, Office 310, Agioi Omologites, 1087 Nicosia, Cyprus
            and Registration number: HE 410775
          </p>

          <ul className="d-none">
            <li>
              <span>Support</span>
              <a href="#">support@barbarjeeto.com</a>
            </li>
            <li>
              <span>Partners</span>
              <a href="#">partnes@barbarjeeto.com</a>
            </li>
            <li>
              <span>Press</span>
              <a href="#">press@barbarjeeto.com</a>
            </li>
          </ul>
          <ul className="SecurityLogo">
            <li>
              <img src={Security} alt="Security" />
            </li>
            <li>
              <img src={Security1} alt="Security" />
            </li>
          </ul>
        </div>
        <div className="copy-right"></div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
          <ul className="d-flex m-0 list-unstyled">
            <li  onClick={() => handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)}>
              <a className="active" href="#">
                <img src={HomeIcon} />
                <span>Search</span>
              </a>
            </li>
            <li onClick={() => handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)}>
              <a href="#">
                <img src={CasinoIcon} />
                <span>Casino</span>
              </a>
            </li>
            <li>
              <a className="homeactive" href="/">
                <img src={MenuIcon} />
                {/* <span>Menu</span> */}
              </a>
            </li>
            <li onClick={() => handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)}>
              <a href="#">
                <img src={SportsIcon1} />
                <span>Sports</span>
              </a>
            </li>

            <li>
              <a
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={SupportIcon} />
                <span>Support</span>
              </a>
            </li>
          </ul>
        </div>
      </Container>
      <LoginModal
        show={showAuthModals.login}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
        handleModalShow={handleModalShow}
      />
      <MobileNumberModal
        show={showAuthModals.mobileNumber}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)}
        handleModalShow={handleModalShow}
      />
      <MobileNumberVerifyModal
        show={showAuthModals.verifyNumber}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)}
        handleModalShow={handleModalShow}
      />
      <RegisterModal
        show={showAuthModals.register}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
        handleModalShow={handleModalShow}
      />
    </footer>
  );
};

export default BeforeLoginFooter;
