import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { mobileVerification } from "../../../redux/auth/actions";
import { Toaster } from "../../../utils/toaster";
import { useDispatch } from "react-redux";
import { APP_CONST } from "../../../config/const";

import FacebookModal from "../../../assets/images/icons/socialiocns/facebook.png";
import TelegramModal from "../../../assets/images/icons/socialiocns/telegram.png";
import InstagramModal from "../../../assets/images/icons/socialiocns/instagram.png";
import WhatsappModal from "../../../assets/images/icons/socialiocns/whatsapp.png";

const MobileNumberVerifyModal = ({ show, handleClose, handleModalShow }) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [otp, setOtpValue] = useState("");
  const dispatch = useDispatch();

  const getMobileDetails = () => {
    const details =
      localStorage.getItem("mobile_details") &&
      JSON.parse(localStorage.getItem("mobile_details"));
    return details || {};
  };

  const handleSubmit = () => {
    setIsVerifying(true);

    dispatch(mobileVerification({ ...getMobileDetails(), otp }))
      .then((res) => {
        setIsVerifying(false);
        handleClose();
        handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
      })
      .catch((err) => {
        setIsVerifying(false);
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
      });
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header closeButton onClick={handleClose} closeVariant="white">
        {/* <Modal.Title>Sign in now!</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="login-section">
          {/* <div className="login-leftPart">
            <img src={LoginLeftPart} />
          </div> */}
          <div className="Login-rightPart">
            <Modal.Title>Verify Number</Modal.Title>
            <div className="form-steps">
              <h2 className="h2">Verify!</h2>
              <p>
                Please enter OTP you received on your mobile number and verify.
              </p>
              <Form>
                <Form.Group
                  className="mb-3 position-relative"
                  controlId="formBasicPassword"
                >
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP*"
                    onChange={(e) => setOtpValue(e.target.value)}
                  />
                  <span className="icon">{/* <EyeIcon /> */}</span>
                </Form.Group>
              </Form>

              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={isVerifying}
              >
                {!isVerifying ? "VERIFY" : "SUBMITTING"}
              </Button>
              <div className="sign-up mt-4">
                Already a member?
                <br />
                <a
                  href="#"
                  onClick={() => {
                    handleClose();
                    handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                  }}
                >
                  Sign In here
                </a>
              </div>

              <div className="social-icon-modal">
                <ul>
                  <li>
                    <img src={FacebookModal} />
                  </li>
                  <li>
                    <img src={TelegramModal} />
                  </li>
                  <li>
                    <img src={InstagramModal} />
                  </li>
                  <li>
                    <img src={WhatsappModal} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MobileNumberVerifyModal;
