import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { demoLogin, loginUser } from "../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { Container, Row, Col, Button, Dropdown, Form } from "react-bootstrap";
import hide from "../../../assets/svg/hide.png";
import EyeIcon from "../../../assets/svg/EyeIcon";
import { APP_CONST } from "../../../config/const";

import FacebookModal from "../../../assets/images/icons/socialiocns/facebook-app-symbol.png";
import TelegramModal from "../../../assets/images/icons/socialiocns/paper.png";
import InstagramModal from "../../../assets/images/icons/socialiocns/instagram-icon.png";
import WhatsappModal from "../../../assets/images/icons/socialiocns/whatsappIcon.png";
import { checkIfDemoUserExist } from "../../../redux/app/actions";
const LoginModal = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isDemoUserExist, setIsDemoUserExist] = useState(false);
  useEffect(() => {
    checkDemoUser();
  }, []);
  const checkDemoUser = async () => {
    const response = await checkIfDemoUserExist();
    if (response && response?.data?.error === false) {
      setIsDemoUserExist(response?.data?.data?.hasDemoUsers === true);
    }
  };
  const handleDemoLogin = () => {
    dispatch(demoLogin());
  };
  const validateData = () => {
    const { username, password } = formData;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Username cannot be empty";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateData();
    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      let loginData = { ...formData };
      handleClose();
      dispatch(loginUser(loginData));
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header closeButton onClick={handleClose} closeVariant="white">
        {/* <Modal.Title>Sign in now!</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="login-section">
          {/* <div className="login-leftPart d-none">
            <img src={LoginLeftPart} className="signin" />
          </div> */}
          <div className="Login-rightPart">
            <Modal.Title>Sign in</Modal.Title>
            <div className="form-steps">
              {/* <h2 className="h2">Hello Again!</h2>
              <p>
                Welcome back, you've
                <br />
                been missed!
              </p> */}
              <Form>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Control
                    type="text"
                    placeholder="Enter Username*"
                    name="username"
                    onChange={handleChange}
                    isInvalid={!!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.username}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3 position-relative"
                  controlId="formBasicPassword"
                >
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password*"
                    name="password"
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <span
                    className="icon"
                    onClick={() => setShowPassword((s) => !s)}
                  >
                    {showPassword ? <img src={hide} alt="" /> : <EyeIcon />}
                  </span>
                  <Form.Control.Feedback type="invalid">
                    {errors?.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Sign in
                </Button>
                {(isDemoUserExist && (
                  <Button
                    variant="primary"
                    onClick={handleDemoLogin}
                    className=" mt-2"
                  >
                    Login with Demo Id
                  </Button>
                )) ||
                  null}
              </Form>

              <div className="sign-up mt-4">
                <div className="OrBtn">OR</div>
                <div className="social-icon-modal">
                  <ul>
                    <li>
                      <img src={FacebookModal} />
                    </li>
                    <li>
                      <img src={TelegramModal} />
                    </li>
                    <li>
                      <img src={InstagramModal} />
                    </li>
                    <li>
                      <img src={WhatsappModal} />
                    </li>
                  </ul>
                </div>
                <div className="d-flex justify-content-center mt-2 mb-2">
                  <a className="form-link" href="/forgot-password">
                    Forgot Password
                  </a>
                </div>
                <a
                  // href="#"
                  onClick={() => {
                    handleClose();
                    handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER);
                  }}
                >
                  Don’t have an account? Register an account
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;
