import React from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
// import { GamesData } from "./GamesData";
import { slotsData, casinoGamesList } from "../../lib/data/slots.data";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { HomeGamesData } from "../../lib/data";
const GamesSearchModal = () => {
  const [show, setShow] = useState(false);
  const width = window.screen.width;
  const isMobile = width < 800;
  // console.log("isMobile", isMobile);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  // console.log("isFocused", isFocused);
  const totalGames = {
    ...HomeGamesData.PopularGames,
    ...HomeGamesData.AndarBaharGames,
    ...HomeGamesData.RouletteGames,
    ...HomeGamesData.GameShows,
    ...HomeGamesData.TeenPattiGames,
    ...HomeGamesData.DragonTigerGames,
    ...HomeGamesData.BaccaratGames,
    ...HomeGamesData.ProvidersGames,
    ...HomeGamesData.SlotsGames,
  };

  const handleSearch = (e) => {
    if (e) {
      setSearchText(true);

      let filteredValue = Object.values(totalGames)?.filter((item) => {
        return (
          item.name && item.name.toLowerCase().indexOf(e?.toLowerCase()) > -1
        );
      });
      setFilteredData(filteredValue);
      // console.log("Search Data", filteredValue);
    } else {
      setSearchText(false);
    }
  };
  // console.log("FilterData", filteredData);
  return (
    <div>
      <div className="d-none">
        <div
          className="searchGames"
          onChange={(e) => handleSearch(e.target.value)}
          autoFocus
          onFocus={() => {
            setIsFocused(true);
          }}
        >
          <BiSearch />
          <InputGroup className=" search-icon">
            <Form.Control
              placeholder="Search your favourite games"
              aria-label="Text input with dropdown button"
              type="search"
              onChange={(e) => handleSearch(e.target.value)}
              autoFocus
              onFocus={() => {
                setIsFocused(true);
              }}
            />
          </InputGroup>
        </div>
      </div>
      <div>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" className="searchGames">
            <BiSearch />
            <InputGroup className=" search-icon">
              <Form.Control
                placeholder="Search your favourite games"
                aria-label="Text input with dropdown button"
                type="search"
                onChange={(e) => handleSearch(e.target.value)}
                autoFocus
                onFocus={() => {
                  setIsFocused(true);
                }}
              />
            </InputGroup>
          </Dropdown.Toggle>

          {!isMobile && (
            <DropdownMenu className="SearchGamesDropDown">
              <div className="GamesShow">
                {filteredData &&
                  filteredData.map((item) => (
                    <div className="displayGames">
                      <a href={item.href}>
                        {/* {item.imgUrl ? <img src={item.imgUrl} /> : item.img} */}
                        {<img src={item?.cover?.src} />}
                      </a>
                    </div>
                  ))}
              </div>
            </DropdownMenu>
          )}

          {isMobile && isFocused && (
            <div className="GamesShow">
              {filteredData &&
                filteredData.map((item) => (
                  <div className="displayGames">
                    <a href={item.href}>
                      {/* {item.imgUrl ? <img src={item.imgUrl} /> : item.img} */}
                      {<img src={item?.cover?.src} />}
                    </a>
                  </div>
                ))}
            </div>
          )}
        </Dropdown>
        <Modal show={show} onHide={handleClose} className="searchGames1">
          <Modal.Header closeButton>
            <Modal.Title>Search your favourite games</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <InputGroup className="mb-3 search-icon">
              <Form.Control
                placeholder="Search"
                aria-label="Text input with dropdown button"
                type="search"
                onChange={(e) => handleSearch(e.target.value)}
                autoFocus
              />
            </InputGroup>
            <div className="GamesShow">
              {filteredData &&
                filteredData.map((item) => (
                  <div className="displayGames">
                    <a href={item.redirectUrl}>
                      {item.imgUrl ? <img src={item.imgUrl} /> : item.img}
                    </a>
                  </div>
                ))}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default GamesSearchModal;
